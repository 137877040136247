import React, { useState } from "react";

export const Email = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
  const phoneRegex = /^\+?(\d.*){3,}$/; 

  if (!formData.firstName.trim()) { alert("First name is required"); return; } 
  if (!formData.lastName.trim()) { alert("Last name is required"); return; } 
  if (!formData.message.trim()) { alert("Message is required"); return; } 
  if (!emailRegex.test(formData.email)) { alert("Invalid email address"); return; } 
  if (!phoneRegex.test(formData.phone) || formData.phone.length <10 ) { alert("Invalid phone number"); return; }

  setIsLoading(true); // Set loading to true *before* fetch

    try {
      const response = await fetch("https://sendemail-dv4m.onrender.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          recipient: "joshua.joel.vasquez@gmail.com",
        }),
      });

      const responseData = await response.json();
      
      if (response.ok && responseData.success) { // Check *both* response.ok and a success flag in the response
        alert("Message sent successfully!");

        setFormData({ // Reset only if *truly* successful
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        const errorMessage = responseData.message || "Failed to send message."; // Extract server error or use a default
        alert(errorMessage); // Display the error message from the server
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later."); // Generic error message for network issues, etc.
    }finally {
      setIsLoading(false); // Set loading to false *after* fetch
    }
  };

  return (
    <section className="email" id="emails">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Contact me</h2>
              <p>I will get back to you as soon as possible</p>
              <form onSubmit={handleSubmit}>
                <label className="form-label">
                  <p>First Name:</p>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </label>
                &ensp;
                <label className="form-label">
                  <p>Last Name:</p>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </label>
                <br/>
                <label className="form-label">
                  <p>Email:</p>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <br/>
                <label className="form-label">
                  <p>Phone Number:</p>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </label>
                <br/>
                <label className="form-message">
                  <p>Message:</p>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </label>
                <br/>
                <button className="btn-email" type="submit" disabled={isLoading}>  {/* Added disabled */}
                  {isLoading ? "Sending..." : "Send"} {/* Added conditional text */}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Email;
