import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useState, useEffect } from "react";  // Import necessary hooks

// Import the JSON file
import projectsData from '../assets/ProjectExperience.json';

export const Projects = () => {
  const [projects, setProjects] = useState([]);

  // Using useEffect to set the projects from JSON file
  useEffect(() => {
    const resolvedProjects = projectsData.map((project) => ({
      ...project,
      imgUrl: require(`../${project.imgUrl}`), // Dynamically resolve image paths
    }));
    setProjects(resolvedProjects);
  }, []);
  

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Experience</h2>
                  <p>
                    My journey in the tech industry began during my tenure as an Intern at ComEd,
                    where I honed my skills in data and technology. From there, I’ve been growing to become more adept in today's technologies.
                    I've always been passionate about leveraging technology to solve real-world problems.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row>
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              );
                            })
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background decorative" />
    </section>
  );
};
